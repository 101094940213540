import React from 'react';
import './App.css';

function App() {
    return (
        <div className="landing-page">
            <div className="text-container">
                <div className="black-section">
                    <span className="black-bg">misiewi</span>
                </div>
                <div className="white-section">
                    <span className="white-bg">.cz</span>
                </div>
            </div>
        </div>
    );
}

export default App;
